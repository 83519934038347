
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "NarrativeReportModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id"),
      narrative_report_file: "",
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };
          }
        }
    );


    const uploadFile = (event) => {
      formData.value.narrative_report_file = event.target.files[0];
    };

    const schema = object().shape({
      // knowledge_file: string().required("file is required"),
      component_id: number().required("Component is required"),
      knowledge_management_type_id: number().required("File Type is required"),
    });

    const errors = ref({});

    const save = async () => {
      try {
        // await schema.validate(formData.value, { abortEarly: false });
        ApiService.setHeaderforImage();
        const formDataObj = new FormData();
        formDataObj.append("narrative_report_file", formData.value.narrative_report_file);
        formDataObj.append("created_by", formData.value.created_by);

        ApiService.post(
            apiEndpoint.data().VUE_APP_NARRATIVE_REPORT_STORE,
            formDataObj
        )
            .then((response) => {
              if (response.data.status == "success") {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.data,
                  type: "success",
                });
                DrawerComponent.hideAll();
                bus.emit("loadData", true);
              } else {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.msg,
                  type: "error",
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });

        formData.value = {
          id: "",
          updated_by: "",
          created_by: VueCookieNext.getCookie("_user_id"),
          narrative_report_file: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    return {
      formData,
      errors,
      save,
      uploadFile,
    };
  },
});
