
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import NarrativeReportModal from "@/components/modals/forms/report/NarrativeReportModal.vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";
import { formatDate } from "@/core/helpers/genericHelper.ts";

export default defineComponent({
  name: "narrativeReport",
  components: {
    NarrativeReportModal,
  },
  setup() {
    const { bus } = useBus();
    const editData = ref(null);
    const narrativeReportList = ref([]);
    const currentPage = ref(1);
    const perPageOptions = [10, 20, 50];
    const selectedPerPage = ref(perPageOptions[0]);
    const totalRows = ref(0);

    const handleSizeChange = (val) => {
      selectedPerPage.value = val;
      loadData();
    };

    const handleCurrentChange = (val) => {
      currentPage.value = val;
      loadData();
    };

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (data) => {
      editData.value = data;
    };

    const show = (item) => {
      // Add logic for showing item
    };

    const destroy = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_NARRATIVE_REPORT_DELETE,
              {
                id: id,
              }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_NARRATIVE_REPORT_LIST,
          {
            page: currentPage.value,
            per_page: selectedPerPage.value,
          }
        );
        narrativeReportList.value = response.data.data.data;
        totalRows.value = response.data.data.total;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const downloadFile = (fileLink) => {
      const anchor = document.createElement("a");
      anchor.href =
        apiEndpoint.data().BEE_FILE_URL + fileLink;
      anchor.target = "_blank";
      anchor.download = "";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    const downloadImportTemplate = () => {
      const anchor = document.createElement("a");
      anchor.href = apiEndpoint.data().BEE_FILE_URL + '/storage/import_template/Narrative_Report_Template.docx';
      anchor.target = '_blank';
      anchor.download = 'Narrative_Report_Template.docx';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return {
      editData,
      currentPage,
      perPageOptions,
      selectedPerPage,
      totalRows,
      handleSizeChange,
      handleCurrentChange,
      narrativeReportList,
      downloadImportTemplate,
      create,
      edit,
      show,
      destroy,
      downloadFile,
      formatDate,
    };
  },
});
